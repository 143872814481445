export enum Skill {
  HTML = 'HTML',
  CSS = 'CSS',
  Javascript = 'Javascript',
  React = 'React',
  NextJS = 'Next JS',
  NuxtJS = 'Nuxt JS',
  NodeJS = 'Node JS',
  Vue = 'Vue',
  Angular = 'Angular',
  Docker = 'Docker',
  Photoshop = 'Photoshop',
  Illustrator = 'Illustrator',
  Svelte = 'Svelte',
  GCP = 'GCP',
  Azure = 'Azure',
  Fastify = 'Fastify',
  Haxe = 'Haxe',
  Ionic = 'Ionic',
  Markdown = 'Markdown',
  MicrosoftOffice = 'Microsoft Office',
  Picsart = 'Picsart',
  Sketch = 'Sketch',
  Unity = 'Unity',
  WolframAlpha = 'WolframAlpha',
  AdobeXD = 'Adobe XD',
  AfterEffects = 'After Effects',
  Bootstrap = 'Bootstrap',
  Bulma = 'Bulma',
  CapacitorJs = 'CapacitorJs',
  Coffeescript = 'Coffeescript',
  MemSQL = 'MemSQL',
  C = 'C',
  CPlusPlus = 'C++',
  CSharp = 'C#',
  Python = 'Python',
  Java = 'Java',
  Julia = 'Julia',
  Matlab = 'Matlab',
  Swift = 'Swift',
  Ruby = 'Ruby',
  Kotlin = 'Kotlin',
  Go = 'Go',
  PHP = 'PHP',
  Flutter = 'Flutter',
  Dart = 'Dart',
  Typescript = 'Typescript',
  Git = 'Git',
  Figma = 'Figma',
  Canva = 'Canva',
  Ubuntu = 'Ubuntu',
  MongoDB = 'MongoDB',
  Tailwind = 'Tailwind',
  ViteJS = 'ViteJS',
  VuetifyJS = 'VuetifyJS',
  MySQL = 'MySQL',
  PostgreSQL = 'PostgreSQL',
  AWS = 'AWS',
  Firebase = 'Firebase',
  Blender = 'Blender',
  PremierePro = 'Premiere Pro',
  AdobeAudition = 'Adobe Audition',
  Deno = 'Deno',
  Django = 'Django',
  Gimp = 'Gimp',
  Graphql = 'Graphql',
  Lightroom = 'Lightroom',
  MaterialUI = 'MaterialUI',
  Nginx = 'Nginx',
  Numpy = 'Numpy',
  OpenCV = 'OpenCV',
  Pytorch = 'Pytorch',
  Selenium = 'Selenium',
  Strapi = 'Strapi',
  Tensorflow = 'Tensorflow',
  Webex = 'Webex',
  Wordpress = 'Wordpress'
}

export const skills: Skill[] = [
  Skill.AWS,
  Skill.CSS,
  Skill.Gimp,
  Skill.Git,
  Skill.HTML,
  Skill.React,
  Skill.Typescript,
  Skill.NodeJS,
  Skill.Java,
  Skill.Javascript,
  Skill.Markdown,
  Skill.MaterialUI,
  Skill.MongoDB,
  Skill.MySQL,
  Skill.NextJS,
  Skill.Nginx,
  Skill.PHP,
  Skill.PostgreSQL,
  Skill.ViteJS,
  Skill.Vue,
];

export const highlighedSkills: Skill[] = [
  Skill.Typescript,
  Skill.React,
  Skill.NodeJS,
  Skill.PostgreSQL,
];
